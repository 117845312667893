import React, {Component} from "react";
import '../css/Scritte.css';
import 'animate.css';
import { Fade } from "react-awesome-reveal";

class Scritte extends Component{
    render(){
        return(
            <div id="sec-2"> 
                <Fade>
                <h3 className=" title animate__animated has-text-weight-semibold animate__slideInLeft" style={{"animationDelay":"0.5s"}}>Chi Siamo?</h3>
                <h3 className="scrittura-body animate__animated animate__fadeInUp" style={{"animationDelay":"1s"}} >La nostra azienda nasce nel 1956 a Manzano (UD), attualmente gestita da Andrea Bolzicco e Samuele Campanella. Nel 2004 prese il nome di BOL.CAM sedie e si occupa della realizzazione di sedie in legno, da ormai più di sessant'anni. Collaboriamo con le migliori aziende produttrici di legno della zona e i materiali utilizzati vengono accuratamente selezionati ogni giorno. In quanto piccola attività  ci assicuriamo che tutti i dettagli del singolo pezzo siano perfetti.</h3>
                </Fade> 
            <br></br>
            </div>
        );
    }
}

export default Scritte;