import React, {Component} from "react";
import '../css/Navbar.css'
import logo from '../immagini/logo.webp';

class Navbar extends Component{
    render(){
        function toggleBurgerMenu() {
            document.querySelector('.navbar-menu').classList.toggle('is-active');
            document.querySelector('.navbar-brand').classList.toggle('is-active');
            document.querySelector('.navbar-burger').classList.toggle('is-active');
          }
        return(
            <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
                <div class="navbar-brand">
                    <a class="navbar-item" href="index.html">
                    <img className="logo" src={logo} />
                    </a>
                    <a class="navbar-item is-size-4 is-size-5-touch has-text-weight-semibold">
                        BOL.CAM Sedie
                    </a>
                    <a role="button" className="navbar-burger burger is-heigth-0" aria-label="menu" aria-expanded="false" data-target="navbarBasic"
                    onClick={toggleBurgerMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                    </a>
                </div>
                <div class="navbar-end">
                <div id="nav-link" class="navbar-menu">
                    <a id="sec-1" class="navbar-item is-size-6 has-text-weight-medium" href="#sec-2">
                        Azienda
                    </a>
                    <a class="navbar-item is-size-6 has-text-weight-medium" href="https://www.google.it/maps/place/Bol.Cam+Sedie+Di+Bolzicco+Andrea+E+Campanella+Samuele+Snc/@46.0222126,13.3926742,365m/data=!3m1!1e3!4m6!3m5!1s0x477bb2c828d436dd:0x2eb3e918be88df14!8m2!3d46.0224451!4d13.3924637!16s%2Fg%2F1thcr57h?entry=ttu">
                        Dove Siamo?
                    </a>
                    <a class="navbar-item is-size-6 has-text-weight-medium" href="#sec-3">
                        Contatti
                    </a>
                </div> 
                </div>
                </nav>
        );
    }
}

export default Navbar;