import React, {Component} from "react";
import Carousel from "nuka-carousel";
import fotolegno1 from "../immagini/foto legno.webp";
import fotolegno2 from "../immagini/foto legno 2.webp";
import fotolegno3 from "../immagini/foto legno 3.webp";
import "../css/Carousel.css";
import 'animate.css'

class Carousel1 extends Component{
    render(){ 
        return(
            <Carousel 
                autoplayInterval={4000} 
                autoplay={true} 
                wrapAround={true}
                defaultControlsConfig={{ prevButtonText: "<", nextButtonText: ">", prevButtonStyle:"" }} >
                    
                    <div className="item">
                        <img src={fotolegno1} />
                        <div className="absolute-div">
                            <div className="textontop animate__animated animate__fadeInDown" style={{"animationDelay":"0.7s"}}>
                                <h2>Legno 100% italiano fornito da aziende locali</h2>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <img src={fotolegno2} />
                        <div className="absolute-div">
                        <div className="textontop animate__animated animate__fadeInDown" style={{"animationDelay":"5s"}}>
                                <h2>Creiamo con cura i pezzi presso la nostra azienda</h2>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <img src={fotolegno3} />
                        <div className="absolute-div">
                        <div className="textontop animate__animated animate__fadeInDown" style={{"animationDelay":"9s"}}>
                                <h2>Collaboriamo con molte aziende per la produzione delle sedie</h2>
                            </div>
                        </div>
                    </div>
                    
            </Carousel>
        );
    }
}

export default Carousel1;