import React, {Component} from "react";
import FotoSedia from '../immagini/sedie-card.webp';
import FotoSedia2 from '../immagini/sedie-card2.webp';
import FotoSedia3 from '../immagini/sedie-card3.webp';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import '../css/Card.css';

class Schede extends Component{
    render(){
        return(
            <div class="wrapper">
            <Card sx={{ maxWidth: 400 }}>
            <CardMedia
                sx={{ height: 400 }}
                image={FotoSedia}
                title="Produzione"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" className="testo">
                Produzione
                </Typography>
                <Typography className="testo" variant="body2">
                Curiamo ogni singolo dettaglio del pezzo con massima attenzione
                </Typography>
            </CardContent>
            </Card>

            <Card sx={{ maxWidth: 345 }}>
            <CardMedia
                sx={{ height: 400 }}
                image={FotoSedia3}
                title="Collaboriamo"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" className="testo">
                Collaborazione
                </Typography>
                <Typography variant="body2" className="testo">
                Collaboriamo con numerose aziende della zona per la lavorazione e la produzione dei prodotti
                </Typography>
            </CardContent>
            </Card>
            
            <Card sx={{ maxWidth: 345 }}>
            <CardMedia
                sx={{ height: 400 }}
                image={FotoSedia2}
                title="Lavorazione"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" className="testo">
                Lavorazione
                </Typography>
                <Typography variant="body2" className="testo">
                Creiamo su richiesta sedie e singoli pezzi necessari per la costruzione
                </Typography>
            </CardContent>
            </Card>
            </div>
        );
    }
}

export default Schede;