import React, {Component} from "react";
import CountUp from 'react-countup';
import '../css/Contatore.css';

class Contatore extends Component{
    render(){
        return(
            <table className="tabella">
                <br></br>
                <tr><td>Anni di Attività</td><td>Pezzi Creati</td></tr>
                <tr><td><CountUp start={0} end={60} delay={1.5} duration={4.5} enableScrollSpy={true} scrollSpyDelay={2} />+</td>
                <td><CountUp start={0} end={10000} delay={1.5} duration={4} enableScrollSpy={true} scrollSpyDelay={2} />+</td></tr>
                <br></br>
            </table>
        );
    }
}

export default Contatore;