import React, {Component} from 'react';
import './css/App.css';
import Navbar from './JS/Navbar';
import Scritte from './JS/scritte';
import Contatti from './JS/Contatti';
import Carousel1 from './JS/Carousel';
import Contatore from './JS/Contatore';
import Schede from './JS/Card';

class App extends Component{
  render(){
    return(
      <body>
        <header>
          <Navbar />
          <Carousel1 />
        </header>
        <container>
          <Scritte />
          <div className='colore'>
          <Contatore />
          </div>
          <Schede />
        </container>
        <footer>
          <Contatti />
          <br></br>
        </footer>
      </body>
    );
  }
}

export default App;
